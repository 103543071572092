
<template>
  <el-dialog title="编辑班级" :visible.sync="openDialog" :show-close="false" width="500px" v-loading="loading" class="dialog-vertical">
    <el-form :model="form" :rules="rules" 
     ref="postForm" label-position="right">
        <el-form-item label="学段：" prop="period_id">
          <el-select v-model="form.period_name" :loading="selectLoading" placeholder="请选择">
            <el-option v-for="item in selection.periodList" :key="item.id" :label="item.name" :value="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="校区：" prop="campus_id">
          <el-select v-model="form.campus_name" :loading="selectLoading" placeholder="请选择">
             <el-option v-for="item in selection.campusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="毕业年份：" prop="entrance_year">
          <el-select style="margin-right: 30px" :loading="selectLoading" v-model="form.entrance_year" placeholder="请选择">
             <el-option v-for="(item, index) in selection.yearList" :label="item.name" :key="index" :value="item.name
             "></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="班级：" prop="id">
          <el-select v-model="form.name" :loading="selectLoading" placeholder="请选择">
             <el-option v-for="item in selection.ClassList" :key="item.id" :label="item.name" :value="item.name"></el-option>
          </el-select>
        </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="openDialog = false">取 消</el-button>
      <el-button type="primary" @click="add('postForm')">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { saveClasses, searchClass } from "../../api/member/class-message";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    formItem: {
      type: Object,
    },
    selection: {
      type: Object
    },
    updateList: {
      type: Function
    },
    ClassForm: {
      type: Array
    }
  },
  data() {
    return {
      form: {
        id: '',
        class_id: '',
        period_id: '', // 学段
        campus_id: '', // 校区
        teacher: '',
      },
      rules: {
        id: [
          { required: true, validator: this.classIdPass, trigger: 'blur'}
        ],
        period_id: [
          { required: true, validator: this.periodPass, trigger: 'change'}
        ],
        campus_id: [
          { required: true, validator: this.campusPass, trigger: 'change'}
        ],
        entrance_year: [
          { required: true, validator: this.yearPass, trigger: 'change'}
        ],
      },
      loading: false,
      selectLoading: false
    };
  },
  methods: {
    add(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          const data = {
              user_id: this.$deCode(this.$route.params.data).id,
              id: this.formItem.id,
              class_id: this.form.id
          }
          console.log(this.formItem.id, this.form.id)
          saveClasses(data)
            .then(res => {
             this.loading = false
             this.openDialog = false
             this.$message.success(res.msg)
             this.updateList()
            })
            .catch(err => {
              this.loading = false
            })
          }
      });
    },
    // 名称验证
    classIdPass(rule, value, callback) {
      if (value) {
        if (this.form.period_name === '') {
          this.$message.error('请先选择学段')
          this.form.class_id = ''
        } else if (this.form.entrance_year === '') {
          this.$message.error('请先选择毕业年份')
          this.form.class_id = ''
        } else if (this.form.campus_name === '') {
          this.$message.error('请先选择校区')
          this.form.class_id = ''
        } else {
          callback()
        }
      } else {
        callback(callback(new Error("请选择班别")));
      }
    },
    // 学段验证
    periodPass(rule, value, callback) {
      if (value) {
        callback()
      } else {
        callback(callback(new Error("请选择学段")));
      }
    },
    // 校区校验
    campusPass(rule, value, callback) {
      if (value) {
        if (this.form.period_name === '') {
          this.$message.error('请先选择学段')
          this.form.campus_name = ''
        } else {
          callback()
        }
      } else {
        callback(callback(new Error("请选择校区")));
      }
    },
    // 年份校验
    yearPass(rule, value, callback) {
      if (value) {
        if (this.form.period_name === '') {
          this.$message.error('请先选择学段')
          this.form.entrance_year = ''
          this.allKey.yearKey = false
        }else if (this.form.campus_name === '') {
          this.$message.error('请先选择校区')
          this.form.entrance_year = ''
        } else {
          callback()
        }
      } else {
        callback(callback(new Error("请选择毕业年份")));
      }
    },
    async getSearch(data) {
      this.selectLoading = true
      await searchClass(data).then(res => {
        console.log(res)
        this.selection.periodList = res.data.period
        this.selection.campusList = res.data.campus
        this.selection.yearList = res.data.entrance_year
        this.selection.ClassList = res.data.class
        this.selectLoading = false
      }).catch(err => console.log(err))
    }
  },
  watch: {
    form: {
      deep: true,
      handler(val, oldVal) {
        this.ClassForm.forEach(item => {
        if (item.name === this.formItem.class_name) {
          return this.form = item
          }
        })
        this.selection.periodList.forEach(item => {
          if (item.name == this.form.period_name) {
          return this.form.period_id = item.id
          }
        })
        this.selection.campusList.forEach(item => {
          if (item.name == this.form.campus_name) {
          return this.form.campus_id = item.id
          }
        })
        this.selection.ClassList.forEach(item => {
          if (item.name == this.form.name) {
          return this.form.id = item.id
          }
        })
        // console.log(val, oldVal)
        // if (val.period_id != oldVal.period_id && oldVal.period_id) {
        //   const data = {
        //     period_id: val.period_id,
        //     campus_id: '',
        //     entrance_year: ''
        //   }
        //   this.form.campus_id = ''
        //   this.form.campus_name = ''
        //   this.form.entrance_year = ''
        //   this.form.name = ''
        //   this.form.id = ''
        //   this.getSearch(data)
        // } else {
          const data = {
            period_id: val.period_id,
            campus_id: val.campus_id,
            entrance_year: val.entrance_year
          }
          this.getSearch(data)
        // }
      }
    }
  },
  computed: {
    openDialog: {
      get() {
        this.ClassForm.forEach(item => {
        if (item.name === this.formItem.class_name) {
          return this.form = item
          }
        })
        this.selection.periodList.forEach(item => {
          if (item.name == this.form.period_name) {
          return this.form.period_id = item.id
          }
        })
        this.selection.campusList.forEach(item => {
          if (item.name == this.form.campus_name) {
          return this.form.campus_id = item.id
          }
        })
        this.selection.ClassList.forEach(item => {
          if (item.name == this.form.name) {
          return this.form.id = item.id
          }
        })
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-vertical {
    .el-form {
        .el-form-item {
            display: flex;
            justify-content: center;
        }
    }
}
</style>
