<template>
  <el-dialog
    title="添加班级"
    :visible.sync="openDialog"
    width="500px"
    :show-close="false"
    v-loading="loading"
    class="dialog-vertical"
  >
    <el-form :model="form" :rules="rules" ref="postForm" label-position="right">
      <el-form-item label="学段：" prop="period_name" required>
        <el-select v-model="form.period_name" placeholder="请选择">
          <el-option v-for="item in selection.periodList"  :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="校区：" prop="campus_name" required>
        <el-select v-model="form.campus_name" :loading="selectLoading" placeholder="请选择">
          <el-option v-for="item in selection.campusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="毕业年份：" prop="entrance_year">
        <el-select style="margin-right: 28px" :loading="selectLoading" v-model="form.entrance_year" placeholder="请选择">
          <el-option v-for="(item, index) in selection.yearList" :label="item.name" :key="index" :value="item.name"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="班级：" prop="class_id">
        <el-select v-model="form.class_id" :loading="selectLoading" placeholder="请选择">
          <el-option v-for="item in selection.ClassList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="add('postForm')">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { saveClasses, searchClass } from "../../api/member/class-message";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    selection: {
      type: Object,
    },
    formItem: {
      type: Object,
    },
    updateList: {
      type: Function,
    },
    ClassForm: {
      type: Array,
    },
    notPost: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        id: 0,
        class_id: "",
        campus_name: "", // 校区
        period_name: "",
        entrance_year: "",
      },
      selectLoading: false,
      rules: {
        class_id: [{ required: true, validator: this.classIdPass, trigger: "blur" }],
        period_name: [{ required: true, validator: this.periodPass, trigger: "change" }],
        campus_name: [{ required: true, validator: this.campusPass, trigger: "change" }],
        entrance_year: [{ required: true, validator: this.yearPass, trigger: "change" }],
      },
      loading: false,
      proxyData: null,
      proxyIndex: -1,
    };
  },
  methods: {
    edit(proxyData, proxyIndex, form) {
      this.form = JSON.parse(JSON.stringify(form));
      console.log("edit call: ", this.form);
      // this.$emit("editData", {
      //   data: null,
      //   index: externalIndex,
      // });
      this.proxyData = proxyData;
      this.proxyIndex = proxyIndex;
    },
    add(formName, proxyData = undefined) {
      if (this.notPost) {
        console.debug("selection: ", this.selection);
        let className = this.form.entrance_year + "届 ";
        let teacher = null;
        for (let i = 0; i < this.selection.ClassList.length; i++) {
          if (this.selection.ClassList[i].id === this.form.class_id) {
            className += this.selection.ClassList[i].name + " ";
            teacher = this.selection.ClassList[i].teacher;
            if (teacher === null || teacher === "") {
              teacher = "--";
            }
          }
        }
        for (let i = 0; i < this.selection.campusList.length; i++) {
          if (this.selection.campusList[i].id === this.form.campus_name) {
            className += this.selection.campusList[i].name;
          }
        }
        const data = {
          user_id: this.$deCode(this.$route.params.data).id,
          id: 0,
          class_id: this.form.class_id,
          class_name: className,
          teacher: teacher,
          form: JSON.parse(JSON.stringify(this.form)),
        };
        if (this.proxyData !== null && this.proxyIndex !== -1) {
          const originalData = JSON.parse(JSON.stringify(this.proxyData));
          this.proxyData = data;
          this.$emit("editData", this.proxyData, this.proxyIndex, originalData);
          this.close();
          this.proxyData = null;
          this.proxyIndex !== -1;
          return;
        }
        this.$emit("comfirmData", data);
        this.close();
        return;
      }
      this.$refs[formName].validate((valid) => {
        console.log("valid", valid);
        if (valid) {
          this.loading = true;
          const data = {
            user_id: this.$deCode(this.$route.params.data).id,
            id: 0,
            class_id: this.form.class_id,
          };
          saveClasses(data)
            .then((res) => {
              this.openDialog = false;
              this.loading = false;
              this.resetForm(formName);
              this.updateList();
              this.$message.success(res.msg);
            })
            .catch((err) => {
              this.loading = false;
            });
        }
      });
    },
    // 名称验证
    classIdPass(rule, value, callback) {
      if (value) {
        if (this.form.period_name === '') {
          this.$message.error('请先选择学段')
          this.form.class_id = ''
        } else if (this.form.entrance_year === '') {
          this.$message.error('请先选择毕业年份')
          this.form.class_id = ''
        } else if (this.form.campus_name === '') {
          this.$message.error('请先选择校区')
          this.form.class_id = ''
        } else {
          callback()
        }
      } else {
        callback(callback(new Error("请选择班别")));
      }
    },
    // 学段验证
    periodPass(rule, value, callback) {
      if (value) {
          callback()
      } else {
        callback(callback(new Error("请选择学段")));
      }
    },
    // 校区校验
    campusPass(rule, value, callback) {
      if (value) {
        if (this.form.period_name === '') {
          this.$message.error('请先选择学段')
          this.form.campus_name = ''
        } else {
          callback()
        }
      } else {
        callback(callback(new Error("请选择校区")));
      }
    },
    // 年份校验
    yearPass(rule, value, callback) {
      if (value) {
        if (this.form.period_name === '') {
          this.$message.error('请先选择学段')
          this.form.entrance_year = ''
        } else if (this.form.campus_name === '') {
          this.$message.error('请先选择毕业年份')
          this.form.entrance_year = ''
        }  else {
          callback()
        }
      } else {
        callback(callback(new Error("请选择毕业年份")));
      }
    },
    // 重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    close() {
      this.resetForm("postForm");
      this.openDialog = false;
    },
    async getSearch(data) {
      this.selectLoading = true
      await searchClass(data)
        .then((res) => {
          this.selection.periodList = res.data.period;
          this.selection.campusList = res.data.campus;
          this.selection.yearList = res.data.entrance_year;
          this.selection.ClassList = res.data.class;
          this.selectLoading = false
        })
        .catch((err) => console.log(err));
    },
  },
  watch: {
    form: {
      deep: true,
      handler(val, oldVal) {
        const data = {
          period_id: val.period_name,
          campus_id: val.campus_name,
          entrance_year: val.entrance_year,
        };
        this.getSearch(data);
      },
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-vertical {
  .el-form {
    .el-form-item {
      display: flex;
      justify-content: center;
    }
  }
}
</style>