import api from "@/base/utils/request";

// 获取班级信息
export const myClasses = data => {
    return api({
        url: "/admin/cyc/user_view/myClasses",
        method: "post",
        data,
    });
};

// 保存班级信息
export const saveClasses = data => {
    return api({
        url: "/admin/cyc/user_view/saveClass",
        method: "post",
        data,
    });
};

// 班级数据筛选条件
export const searchClass = data => {
    return api({
        url: "/admin/cyc/user_view/searchClass",
        method: "post",
        data,
    });
};

// 班级信息展示
export const classInfoShow = data => {
    return api({
        url: "/admin/cyc/user_view/classInfoShow",
        method: "post",
        data,
    });
};

// 删除
export const delClassInfo = data => {
    return api({
        url: "/admin/cyc/user_view/delClassInfo",
        method: "post",
        data,
    });
};