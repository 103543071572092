<template>
  <div class="member">
    <listLayout ref="ListLayout" :thead="thead" :on-fetch="getList" :filter-form="filterForm">
      <template #top>
        <el-button style="margin-bottom: 10px" size="small" @click="isShowAdd = true" type="primary">添加班级</el-button>
      </template>
      <!-- 班级 -->
      <template slot="item_className" slot-scope="{ row }">
        <span
          >{{ row.entrance_year }}届 {{ row.class_name }} {{ row.class_name[row.class_name.length - 1] === "班" ? "" : "班" }}
          {{ row.campus }}</span
        >
      </template>
      <!-- 操作 -->
      <template v-slot:operation="scope">
        <el-button type="text" size="small" @click="Edit(scope.row)">修改</el-button>
        <el-button type="text" size="small" @click="Del(scope.row)">删除</el-button>
      </template>
      <template slot="item_onShow" slot-scope="{ row }">
        <el-radio :label="row.id" :value="row.on_show ? row.id : row.on_show" @change="handleSelect(row)"
          ><span>{{ " " }}</span></el-radio
        >
      </template>
    </listLayout>
    <!-- 添加班级 -->
    <AddClass
      v-model="isShowAdd"
      :formItem="form"
      :ClassForm="ClassForm"
      :updateList="reflashList"
      :selection="selection"
    ></AddClass>
    <!-- 编辑班级 -->
    <EditClass
      v-model="isShowEdit"
      :formItem="form"
      :ClassForm="ClassForm"
      :updateList="reflashList"
      :selection="selection"
    ></EditClass>
  </div>
</template>

<script>
import AddClass from "../../components/Member/AddClass";
import EditClass from "../../components/Member/EditClass";
import ListLayout from "@/base/layout/ListLayout";
import { ClassMembers } from "../../api/school-class-list";
import { myClasses, delClassInfo, searchClass, classInfoShow } from "../../api/member/class-message";
import { campusAndPeriod } from "../../api/school-campus-period-config";
export default {
  components: { ListLayout, AddClass, EditClass },
  data() {
    return {
      thead: [
        { label: "序号", type: "index", minWidth: 150 },
        { label: "班级", type: "slot", prop: "className", minWidth: 150 },
        { label: "班主任", prop: "teacher", minWidth: 100 },
        { label: "设为展示", type: "slot", prop: "onShow", minWidth: 100 },
        { label: "操作", type: "operation", minWidth: 100 },
      ],
      filterForm: {
        id: this.$deCode(this.$route.params.data).id,
        page: 1,
        page_size: 15,
      },
      pageData: {
        current_page: 1,
      },
      selection: {
        periodList: [],
        campusList: [],
        yearList: [],
        ClassList: [],
      },
      isShowAdd: false,
      isShowEdit: false,
      form: {}, // id
      ClassForm: [], // 班级列表
      SearchForm: [],
      OnShow: 0,
    };
  },
  methods: {
    getList(data) {
      return myClasses(data);
    },
    reflashList() {
      this.$refs.ListLayout.getList();
    },
    Edit(row) {
      this.form = row;
      this.isShowEdit = true;
    },
    // 删除
    Del(row) {
      this.$msgbox
        .confirm("确定要将此数据删除吗？", "提示", {
          type: "error",
        })
        .then((res) => {
          delClassInfo({ id: row.id })
            .then((res) => {
              this.$message.success(res.msg);
              this.reflashList();
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    },
    // 获取班级列表
    getClassMembers() {
      ClassMembers()
        .then((res) => {
          this.ClassForm = res.data.data;
        })
        .catch((err) => console.log(err));
    },
    getSearch() {
      searchClass()
        .then((res) => {
          this.selection.periodList = res.data.period;
          this.selection.campusList = res.data.campus;
          this.selection.yearList = res.data.entrance_year;
          this.selection.ClassList = res.data.class;
        })
        .catch((err) => console.log(err));
    },
    // 设置是否展示
    handleSelect(e) {
      console.log(e);
      classInfoShow({ id: e.id })
        .then((res) => {
          this.reflashList();
          this.$message.success(res.msg);
        })
        .catch((err) => {});
    },
    updateShowChange(index) {
      console.log(this.tbody);
      const data = this.$refs.ListLayout.tbody;
      for (let i = 0; i < data.length; i++) {
        if (data[i].on_show) {
          this.OnShow = index;
        }
      }
    },
  },
  created() {
    this.getSearch();
    this.getClassMembers();
  },
  computed: {
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped></style>
